import React from 'react'

const StatusIcon: React.FC<{ status: 0 | 1 | 2 }> = ({ status }) => {
  return <i className="material-icons" style={{
    color: status === 0 ? '' : (status === 1 ? 'green' : 'red'),
    cursor: 'pointer',
    userSelect: 'none'
  }}>
    {status === 0 || (status === 1 ? 'done' : 'error_outline')}
  </i>
}

export default StatusIcon
