import React, { useMemo, useState, useEffect, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'

import copy from 'clipboard-copy'

import LoadingBar from '../LoadingBar'
import StatusIcon from '../StatusIcon'

import config from '../config'

type Props = {
  accessToken: string
}

const TicketUpdates: React.FC<Props> = ({ accessToken }) => {
  const [ticketStates, setTicketStates] = useState<TicketStateEntryWithTicket[] | null>(null)

  const [serverIsProcessing, setServerIsProcessing] = useState<boolean>(false)

  const [pendingGenerateResets, setPendingGenerateResets] = useState<string[]>([])
  const [pendingSendResets, setPendingSendResets] = useState<string[]>([])

  const axiosInstance = useMemo(() => axios.create({
    baseURL: config.api.baseUrl,
    headers: { 'x-api-key': accessToken }
  }), [accessToken])

  const getTicketStates = useCallback(
    async () => {
      await axiosInstance.get('ticketStates')
        .then((response: AxiosResponse<TicketStateEntry[]>) => {
          setTicketStates(response.data.map(ticketState => ({
            ...ticketState,
            ticketData: JSON.parse(ticketState.jsonTicketData || '{}')
          })))
        })
    },
    [setTicketStates, axiosInstance]
  )

  useEffect(() => {
    getTicketStates()
  }, [getTicketStates])

  const resetTicketStateToGenerate = async (ticketState: TicketStateEntry, key: string) => {
    console.log(accessToken)
    if (!ticketState.isXmlGenerated) return
    setPendingGenerateResets(pending => [...pending, key])
    axiosInstance.patch(
      `ticketState`,
      {
        ...ticketState,
        isXmlGenerated: 0,
        isMessageSent: 0
      },
    )
      .finally(getTicketStates)
      .finally(() => setPendingGenerateResets(pending => pending.filter(pendingKey => pendingKey !== key)))

  }

  const resetTicketStateToSend = async (ticketState: TicketStateEntry, key: string) => {
    if (!ticketState.isXmlGenerated || !ticketState.isMessageSent) return
    setPendingSendResets(pending => [...pending, key])
    axiosInstance.patch(
      `ticketState`,
      {
        ...ticketState,
        isMessageSent: 0
      },
    )
      .finally(getTicketStates)
      .finally(() => setPendingSendResets(pending => pending.filter(pendingKey => pendingKey !== key)))
  }

  const resetTicketStateToLog = async (_ticketState: TicketStateEntry, _key: string) => {
    alert('Sorry, that feature isn\'t ready yet.')
  }

  const getAll = async () => {
    setServerIsProcessing(true)
    axiosInstance.put('tickets/poll')
      .finally(getTicketStates)
      .finally(() => setServerIsProcessing(false))
  }

  const generateAll = async () => {
    setServerIsProcessing(true)
    axiosInstance.put(`tickets/translate`)
      .finally(getTicketStates)
      .finally(() => setServerIsProcessing(false))
  }

  const sendAll = async () => {
    setServerIsProcessing(true)
    axiosInstance.put('tickets/post',)
      .finally(getTicketStates)
      .finally(() => setServerIsProcessing(false))
  }

  const removeEntry = async (ticketState: TicketStateEntry) => {
    setServerIsProcessing(true)
    axiosInstance.delete(
      `ticketState?ticketId=${ticketState.ticketId}&statusId=${ticketState.statusId}&customStatusId=${ticketState.customStatusId}`,
    )
      .finally(getTicketStates)
      .finally(() => setServerIsProcessing(false))
  }

  return <div className="TicketUpdates">
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr'
    }}>

      <button
        title="Query mHelpDesk for tickets that have changed in the past 48 hours and add them here"
        style={{ padding: '5px', margin:'5px' }}
        onClick={getAll}>
        Get recent ticket changes
      </button>
      <button
        title="Convert ticket statuses into messages to be sent to Lactalis"
        style={{ padding: '5px', margin:'5px' }}
        onClick={generateAll}>
        Generate all messages
      </button>
      <button
        title="Send pending messages to Lactalis"
        style={{ padding: '5px', margin:'5px' }}
        onClick={sendAll}>
        Send all messages
      </button>
    </div>
    { serverIsProcessing ? <LoadingBar /> : <div style={{ height: '0.5em', margin: '0.5em 0' }}></div> }

    {ticketStates
      ? <table>
        <thead>
          <tr>
            <th colSpan={7}>Ticket Status</th>
            <th colSpan={3}>Message Status</th>
            <th colSpan={3}>Sending Status</th>
            <th colSpan={2}>Logging Status</th>
          </tr>
          <tr>
            <th>Job #</th>
            <th>ID</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Last Changed</th>
            <th>Date Found</th>
            <th></th>

            <th>Status</th>
            <th>Date</th>
            <th></th>

            <th>Status</th>
            <th>Date</th>
            <th></th>

            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {ticketStates.map(ticketState => {
            const key = [ticketState.ticketId, ticketState.statusId, ticketState.customStatusId].join(',')
            return <tr key={key}>
              <td>{ticketState.ticketData.ticketNumber}</td>
              <td>{ticketState.ticketId}</td>
              <td>{ticketState.ticketData.subject}</td>
              <td>{ticketState.ticketData.ticketStatus}</td>
              <td>{new Date(ticketState.dateTicketLastChanged).toLocaleString('en-AU',  { hour12: false })}</td>
              <td>{new Date(ticketState.dateFirstSeen).toLocaleString('en-AU',  { hour12: false })}</td>
              <td title="Remove entry (will be re-added on next poll)"><button type="button" disabled={serverIsProcessing} className="material-icons option" onClick={() => removeEntry(ticketState)}>delete</button></td>

              {pendingGenerateResets.includes(key)
                ? <td colSpan={6}><LoadingBar /></td>
                : <>
                  <td
                    title={ticketState.isXmlGenerated
                      ? ticketState.xmlMessage + '\n\nClick to copy.'
                      : ''}
                    onClick={() => !!ticketState.isXmlGenerated &&
                      ticketState.xmlMessage &&
                      copy(ticketState.xmlMessage)}>
                    <StatusIcon status={ticketState.isXmlGenerated} />
                  </td>
                  <td>{!!ticketState.isXmlGenerated && ticketState.dateXmlGenerated && new Date(ticketState.dateXmlGenerated).toLocaleString('en-AU', { hour12: false })}</td>
                  {!!ticketState.isXmlGenerated
                    ? <td onClick={() => resetTicketStateToGenerate(ticketState, key)} title="Reset XML state">
                      <button type="button" disabled={serverIsProcessing} className="material-icons option">refresh</button>
                      </td>
                    : <td></td>
                  }
                  {pendingSendResets.includes(key)
                    ? <td colSpan={3}><LoadingBar /></td>
                    : <>
                      <td
                        title={!!ticketState.isMessageSent 
                          ? ticketState.responseStatus + '\n\nClick to copy.'
                          : ''}
                        onClick={() => ticketState.responseStatus &&
                          ticketState.isMessageSent &&
                          copy(ticketState.responseStatus)}>
                          <StatusIcon status={ticketState.isMessageSent} />
                      </td>
                      <td>{!!ticketState.isMessageSent && ticketState.dateMessageSent && new Date(ticketState.dateMessageSent).toLocaleString('en-AU', { hour12: false })}</td>
                        {!!ticketState.isMessageSent
                          ? <td onClick={() => resetTicketStateToSend(ticketState, key)} title="Reset Sent state">
                              <button type="button" disabled={serverIsProcessing} className="material-icons option">refresh</button>
                            </td>
                          : <td></td>
                        }
                    </>
                  }
                </>
              }
              <td><StatusIcon status={ticketState.isLoggedToMHelpDesk} /></td>
              <td>{!!ticketState.isLoggedToMHelpDesk && !!ticketState.dateLoggedToMHelpDesk && new Date(ticketState.dateLoggedToMHelpDesk).toLocaleString('en-AU', { hour12: false })}</td>
            </tr>
          })}
          {ticketStates.length === 100 && <tr><td colSpan={13} style={{ textAlign: 'center', padding: '10px' }}><strong>Note that this table shows only the 100 most recent messages, there may be more.</strong></td></tr>}
        </tbody>
      </table>
      : <h2>No Ticket States</h2>
    }
  </div>
}

export default TicketUpdates
