import React, { useCallback, useEffect, useMemo, useState }  from 'react'

import axios, { AxiosResponse } from 'axios'

import copy from 'clipboard-copy'

import LoadingBar from '../LoadingBar'
import StatusIcon from '../StatusIcon'

import config from '../config'

type Props = {
  accessToken: string
}

const EquipmentUpdates: React.FC<Props> = ({ accessToken }) => {
  const [equipmentStates, setEquipmentStates] = useState<EquipmentStateEntry[] | null>(null)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const axiosInstance = useMemo(() => axios.create({
    baseURL: config.api.baseUrl,
    headers: { 'x-api-key': accessToken }
  }), [accessToken])

  const getEquipmentStates = useCallback(
    async () => {
      await axiosInstance.get('equipmentStates')
        .then((response: AxiosResponse<EquipmentStateEntry[]>) => {
          setEquipmentStates(response.data as EquipmentStateEntry[])
        })
    },
    [setEquipmentStates, axiosInstance]
  )

  useEffect(() => {
    getEquipmentStates()
  }, [getEquipmentStates])

  const pollEquipment = () => {
    setIsProcessing(true)
    axiosInstance.put('equipment/poll')
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const generateMessages = () => {
    setIsProcessing(true)
    axiosInstance.put('equipment/translate')
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const sendMessages = () => {
    setIsProcessing(true)
    axiosInstance.put('equipment/post')
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const removeEntry = (equipmentState: EquipmentStateEntry) => {
    setIsProcessing(true)
    axiosInstance.delete(
      `equipmentStates?assetId=${equipmentState.assetId}&dateLastModified=${equipmentState.dateLastModified}`,
    )
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const resetXmlState = (equipmentState: EquipmentStateEntry) => {
    setIsProcessing(true)
    axiosInstance.patch(
      'equipmentStates',
      {
        ...equipmentState,
        isXmlGenerated: 0,
        isMessageSent: 0,
      },
    )
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const resetMessageSent = (equipmentState: EquipmentStateEntry) => {
    setIsProcessing(true)
    axiosInstance.patch(
      'equipmentStates',
      {
        ...equipmentState,
        isMessageSent: 0,
      },
    )
      .finally(getEquipmentStates)
      .finally(() => setIsProcessing(false))
  }

  const EquipmentStateRow: React.FC<{ equipmentState: EquipmentStateEntry }> = (
    { equipmentState }
  ) => (
    <tr>
      <td>{ equipmentState.assetId }</td>
      <td>{ new Date(equipmentState.dateLastModified)
        .toLocaleString('en-AU', { hour12: false }) }</td>
      <td>{ equipmentState.crn }</td>
      <td>{ equipmentState.serial }</td>
      <td>{ equipmentState.grade }</td>
      <td>{ equipmentState.userStatus }</td>
      <td>
        <button
          onClick={() => removeEntry(equipmentState)}
          disabled={isProcessing}
          className="material-icons option">
          delete
        </button>
      </td>

      <td
        title={equipmentState.isXmlGenerated
          ? equipmentState.xmlMessage + '\n\nClick to copy.'
          : ''}
          onClick={() => equipmentState.xmlMessage &&
            equipmentState.isXmlGenerated &&
            copy(equipmentState.xmlMessage)}>
        <StatusIcon status={equipmentState.isXmlGenerated} />
      </td>
      <td>
        { !!equipmentState.dateXmlGenerated &&
          !!equipmentState.isXmlGenerated &&
          new Date(equipmentState.dateXmlGenerated)
            .toLocaleString('en-AU', { hour12: false })
        }
      </td>
      <td>
        {  !!equipmentState.isXmlGenerated &&
          <button
            onClick={() => resetXmlState(equipmentState)}
            disabled={isProcessing}
            className="material-icons option">
            refresh
          </button>
        }
      </td>

      <td
        title={equipmentState.isMessageSent
          ? equipmentState.responseStatus + '\n\nClick to copy.'
          : ''}
        onClick={() => equipmentState.isMessageSent &&
          equipmentState.responseStatus &&
          copy(equipmentState.responseStatus)}>
        <StatusIcon status={equipmentState.isMessageSent} />
      </td>
      <td>
        {
          !!equipmentState.dateMessageSent &&
          !!equipmentState.isMessageSent &&
          new Date(equipmentState.dateMessageSent)
            .toLocaleString('en-AU', { hour12: false })
        }
      </td>
      <td>
        { !!equipmentState.isMessageSent &&
          <button
            onClick={() => resetMessageSent(equipmentState)}
            disabled={isProcessing}
            className="material-icons option">
            refresh
          </button>
        }
      </td>
    </tr>
  )

  const EquipmentToolbar: React.FC = () => (
    <>
      <div className="toolbar">
        <button
          title="Query mHelpDesk for new or changed equipment."
          onClick={pollEquipment}>
          Poll equipment
        </button>
        <button
          title="Generate messages to send to Lactalis."
          onClick={generateMessages}>
          Generate all messages
        </button>
        <button
          title="Send generated messages to Lactalis."
          onClick={sendMessages}>
          Send all messages
        </button>
      </div>
    </>
  )

  if (!equipmentStates) {
    return <div className="EquipmentUpdates">
      <EquipmentToolbar />
      { isProcessing ? <LoadingBar /> : <div style={{ height: '0.5em', margin: '0.5em 0' }}></div> }
      <h2>No Equipment States</h2>
    </div>
  } else {
    return <div className="EquipmentUpdates">
      <EquipmentToolbar />
      { isProcessing ? <LoadingBar /> : <div style={{ height: '0.5em', margin: '0.5em 0' }}></div> }
      <table>
        <thead>
          <tr>
            <th colSpan={7}>Equipment Status</th>
            <th colSpan={3}>Message Status</th>
            <th colSpan={3}>Sending Status</th>
          </tr>
          <tr>
            <th>Asset ID</th>
            <th>Date Modified</th>
            <th>CRN</th>
            <th>Serial Number</th>
            <th>Grade</th>
            <th>Status</th>
            <th></th>

            <th>Status</th>
            <th>Date</th>
            <th></th>

            <th>Status</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { equipmentStates.map(equipmentState => <EquipmentStateRow equipmentState={equipmentState} />) }
        </tbody>
      </table>
    </div>
  }
}

export default EquipmentUpdates
