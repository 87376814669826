import React, { useState, useEffect } from 'react'

import EquipmentUpdates from './EquipmentUpdates'
import TicketUpdates from './TicketUpdates'

import Cookie from 'universal-cookie'

import './app.css'
import LoadingBar from './LoadingBar'

const baseUrl = 'https://2i34tnqkzi.execute-api.ap-southeast-2.amazonaws.com/default/'
// const baseUrl = 'https://mijqs1gsx4.execute-api.ap-southeast-2.amazonaws.com/default/'

const App: React.FC = () => {
  const cookie = new Cookie()

  const [accessToken, setAccessToken] = useState(cookie.get('pc3_accessToken'))
  const [accessTokenLocked, setAccessTokenLocked] = useState(true)

  // whether ticket or equipment updates are being shown
  const [view, setView] = useState('ticket')
  const switchView = () => {
    if (view === 'ticket') {
      setView('equipment')
    } else {
      setView('ticket')
    }
  }

  useEffect(() => {
    cookie.set('pc3_accessToken', accessToken)
  }, [accessToken, cookie])

  return <div className="App">
    <h2 style={{ textAlign: 'center' }}>Project Chill 3 - Control Panel</h2>
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'auto 3rem',
      gridGap: '1rem',
      padding: '1rem'
    }}>
      <input disabled={accessTokenLocked} type="password" placeholder="Access Token" value={accessToken} onChange={event => setAccessToken(event.target.value)}></input>
      <button className="material-icons" onClick={() => setAccessTokenLocked(is => !is)}>lock</button>
    </div>
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'auto',
    }}>
      <button
        onClick={() => switchView()}
        style={{ padding: '5px', margin:'5px' }}>
        Go to { view === 'ticket' ? 'Equipment Updates' : 'Ticket Updates' }
      </button>
    </div>
    { view === 'ticket' && 
      <TicketUpdates accessToken={accessToken} />
    }
    { view === 'equipment' &&
      <EquipmentUpdates accessToken={accessToken} />
    }
  </div>
}


export default App
